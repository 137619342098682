










import { Component, Vue } from "vue-property-decorator"
import Combobox from "@/components/inputs/Combobox.vue"
import GlobalIndicatorsModule from "@/store/modules/GlobalIndicatorsModule"
import AvailableYearModule from "@/store/modules/AvailableYearModule"

@Component({
  components: {
    Combobox
  }
})
export default class GlobalIndicatorsToolbar extends Vue {
  get years(): number[] {
    const years = []
    for (const year of AvailableYearModule.years) {
      years.push(year.year)
    }
    return years
  }
  get selectedYear(): number | null {
    return GlobalIndicatorsModule.selectedYear
  }

  set selectedYear(year: number | null) {
    GlobalIndicatorsModule.updateSelectedYear(year)
  }

  get regionsNames(): string[] | undefined {
    return GlobalIndicatorsModule.dataset?.regionsNames
  }

  get selectedRegion(): string | null {
    return GlobalIndicatorsModule.selectedRegion
  }

  set selectedRegion(region: string | null) {
    GlobalIndicatorsModule.updateSelectedRegion(region)
  }

  get countriesList(): string[] | null {
    return GlobalIndicatorsModule.selectableCountriesList
  }

  get selectedCountry(): string | null {
    return GlobalIndicatorsModule.selectedCountry
  }

  set selectedCountry(country: string | null) {
    GlobalIndicatorsModule.updateSelectedCountry(country)
  }

  get templatesList(): string[] | null {
    return GlobalIndicatorsModule.selectableTemplatesList
  }

  get selectedTemplate(): string | null {
    return GlobalIndicatorsModule.selectedTemplate
  }

  set selectedTemplate(template: string | null) {
    GlobalIndicatorsModule.updateSelectedTemplate(template)
  }
}
